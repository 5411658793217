'use client';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import FullScreenPopup from '@/components/fullScreenPopup/FullScreenPopup';
import { useCpMediaStore } from '@/store/cpMedia';

import EpisodeVideoPage from './EpisodeVideoPage';
import ImagePage from './ImagePage';
import TrailerVideoPage from './TrailerVideoPage';
import VideoPage from './VideoPage';

import Footer from '../footer/footer';
import Header from '../header/header';

import style from './detailPopup.module.scss';

function DetailPopup() {
    const isOpen = useCpMediaStore.use.openCpMedia();
    const target = useCpMediaStore.use.target();
    const close = useCpMediaStore.use.close();
    const keepUrl = useCpMediaStore.use.keepUrl();

    useEffect(() => {
        const modal = document.getElementById('cpModalLayout');
        if (isOpen) {
            if (modal) modal.style.overflow = 'hidden';
        } else {
            if (modal) modal.style.overflow = 'auto';
        }
        return () => {
            if (modal) modal.style.overflow = 'auto';
        };
    }, [isOpen]);

    const handleClose = () => {
        close();
        window.history.replaceState({}, '', keepUrl);
    };
    const pathname = usePathname();

    const isVideo = ['video', 'drama', 'episode', 'trailer'].includes(target.type);

    return (
        <FullScreenPopup
            isOpen={isOpen}
            hiddenClose
            zIndex={pathname.includes('chat') ? 100 : 1200}
        >
            {isVideo && <Header />}
            <div className={style.detailPopup}>
                {target.type === 'image' && (
                    <ImagePage
                        userId={target.userId}
                        imageId={target.detailId}
                        onClose={handleClose}
                    />
                )}
                {target.type === 'video' && (
                    <VideoPage
                        userId={target.userId}
                        videoId={target.detailId}
                        onClose={handleClose}
                    />
                )}
                {target.type === 'drama' && (
                    <EpisodeVideoPage
                        userId={target.userId}
                        albumId={target.episodeId || ''}
                        episodeId={target.detailId || ''}
                        onClose={handleClose}
                    />
                )}
                {target.type === 'episode' && (
                    <EpisodeVideoPage
                        userId={target.userId}
                        albumId={target.detailId}
                        episodeId={target.episodeId || ''}
                        onClose={handleClose}
                    />
                )}
                {target.type === 'trailer' && (
                    <TrailerVideoPage
                        userId={target.userId}
                        albumId={target.detailId}
                        trailerId={target.trailerId || ''}
                        onClose={handleClose}
                    />
                )}
            </div>
            {isVideo && <Footer />}
        </FullScreenPopup>
    );
}

export default DetailPopup;
