import { useEffect, useState } from 'react';

import { getCpInfo, getCpVideoInfo } from '@/api/couple/cpInfo';
import { useUserStore } from '@/store/user';
import { CpVideoType, CpInfoType } from '@/types/cpType';

import VideoInfo from '../../app/cp/[userId]/(video)/v/[videoId]/videoInfo';

function CpImageInfoPage({
    userId,
    videoId,
    onClose,
    isScrolled,
}: {
    userId: string;
    videoId: string;
    onClose: () => void;
    isScrolled?: boolean;
}) {
    const [userInfo, setUserInfo] = useState<CpInfoType>({} as CpInfoType);
    const [videoInfo, setVideoInfo] = useState<CpVideoType>({} as CpVideoType);
    const user = useUserStore.use.userInfo();

    useEffect(() => {
        const fetchData = async () => {
            const cpRes = await getCpInfo(userId);
            const videoRes = await getCpVideoInfo({
                userId,
                videoId,
                serverAccess: user.access_token
            });

            if (cpRes.status === 200) setUserInfo(cpRes.data);
            if (videoRes.status === 200) setVideoInfo({ ...videoRes.data, isMuted: true });
        };
        fetchData();
    }, [userId, videoId, user.access_token]);

    return (
        userInfo.account &&
        videoInfo.id && <VideoInfo userInfo={userInfo} videoInfo={videoInfo} onClose={onClose} isScrolled={isScrolled ?? false} />
    );
}

export default CpImageInfoPage;
