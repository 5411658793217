'use client';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';

import { getChatInfo } from '@/api/chat/chat';
import { getMessageList, postRead } from '@/lib/websocket';
import { useChatStore } from '@/store/chat';
import { useUserStore } from '@/store/user';
import { ResMessage } from '@/types/chat';

import ChatBody from './chatBody/chatBody';
import ChatFooter from './chatFooter/chatFooter';
import ChatHeader from './chatHeader/chatHeader';
import ChatNotice from './chatNotice/chatNotice';
import useHistory from './hooks/useHistory';
import useLoginHandle from './hooks/useLoginHandle';
import useSyncRead from './hooks/useSyncRead';
import MediaDetail from './mediaPopup/mediaDetail';
import MediaPopup from './mediaPopup/mediaPopup';

import style from './chat.module.scss';

function Chat() {
    const isLogin = useUserStore.use.isLogin();
    const openChat = useChatStore.use.openChat();
    const currentChat = useChatStore.use.currentChat();
    const setChatMapInfo = useChatStore.use.setChatMapInfo();
    const switchChat = useChatStore.use.switchChat();
    const userInfo = useUserStore.use.userInfo();
    const isCp = useUserStore.use.isCp();
    const setCurrentChat = useChatStore.use.setCurrentChat();
    const chatMap = useChatStore.use.chatMap();
    const chatInfo = chatMap[currentChat.session_id]?.chatInfo || {};
    const chatMessageList = chatMap[currentChat.session_id]?.messageList || [];
    const setNewChatList = useChatStore.use.setNewChatList();
    const setChatMapRead = useChatStore.use.setChatMapRead();
    const [showMedia, setShowMedia] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [detailInfo, setDetailInfo] = useState<ResMessage>({} as ResMessage);
    const [isEnd, setIsEnd] = useState(false);
    const [pageMode, setPageMode] = useState(false);
    const [cpIsClose, setCpIsClose] = useState(false);
    const [bodyHeight, setBodyHeight] = useState(0);
    const originHeight = useRef(0);

    const popupX = {
        initial: { x: '100%' },
        animate: { x: 0 }
    };

    useHistory(openChat, currentChat.mode ?? 'user', currentChat.user_id, switchChat);

    useLoginHandle();

    useSyncRead({ openChat, chatMessageList, userInfo });

    useEffect(() => {
        if (currentChat.pageMode) setPageMode(true);
        if (!openChat) setPageMode(false);
    }, [currentChat.pageMode, openChat]);

    const setChatCpUnread = useChatStore.use.setChatCpUnread();
    const setChatUserUnread = useChatStore.use.setChatUserUnread();
    useEffect(() => {
        if (openChat) {
            currentChat.mode === 'cp' && setChatCpUnread(-chatInfo.unread_count);
            currentChat.mode === 'user' && setChatUserUnread(-chatInfo.unread_count);
        }
    }, [openChat, chatInfo.unread_count, currentChat.mode, setChatCpUnread, setChatUserUnread]);

    const setIsOpenSticker = useChatStore.use.setIsOpenSticker();
    useEffect(() => {
        if (!openChat) {
            currentChat.session_id && postRead(currentChat.session_id);
            currentChat.session_id && setChatMapRead(currentChat.session_id);
            setIsEnd(false);
            setCpIsClose(false);
            setIsOpenSticker(false);
        }
    }, [openChat]);

    const keepBodyState = useRef('');

    useEffect(() => {
        if (openChat) {
            keepBodyState.current = document.body.style.overflow;
            document.body.style.overflow = 'hidden';
        } else {
            if (!keepBodyState.current) return;
            document.body.style.overflow = keepBodyState.current;
        }
    }, [openChat]);

    const loadMessage = async () => {
        if (chatMessageList.length === 0) return;
        const lastMessage = chatMessageList[0].id;
        const msgRes = await getMessageList(chatInfo.id, false, lastMessage);
        if (msgRes) {
            setChatMapRead(chatInfo.id);
            postRead(chatInfo.id);
            if (msgRes.length < 20) {
                setIsEnd(true);
            }
        }
    };

    useEffect(() => {
        if (chatMessageList.length > 0) return;
        const fetchData = async () => {
            const res = await getChatInfo({
                role: currentChat.mode ?? 'user',
                to: currentChat.user_id
            });
            if (res.status === 200) {
                setChatMapInfo(res.data);
                const info = res.data;
                const other =
                    info.participants[0].user_id === currentChat.user_id
                        ? info.participants[0]
                        : info.participants[1];
                const newInfo = {
                    account: other.account,
                    avatar: other.avatar,
                    nickname: other.nickname,
                    user_id: other.user_id,
                    status: other.status,
                    description: '',
                    label: other.label,
                    mode: currentChat.mode ?? 'user',
                    session_id: res.data.id,
                    id: res.data.id
                };
                setCurrentChat(newInfo);
                if (!pageMode && currentChat.account) setNewChatList(res.data);
                const msgRes = await getMessageList(res.data.id, true);
                if (msgRes) {
                    setChatMapRead(res.data.id);
                    postRead(res.data.id);
                    if (msgRes.length < 20) {
                        setIsEnd(true);
                    }
                }
            } else {
                if (currentChat.mode === 'user') setCpIsClose(true);
                if (currentChat.mode === 'cp') window.location.href = '/chatroom';
            }
        };
        if (isLogin && currentChat.user_id && openChat) fetchData();
    }, [openChat, currentChat.session_id, chatInfo.id]);

    const handleLoadMore = () => {
        if (!isLogin || !currentChat.user_id || !openChat) return;
        if (!isEnd && chatMessageList.length > 0) loadMessage();
    };

    const switchDetail = (message?: ResMessage) => {
        if (message) {
            setDetailInfo(message);
        }
        setShowDetail(!showDetail);
    };

    const reCalcBodyHeight = (num: number) => {
        setBodyHeight(num);
    };

    const setOriginHeight = (height: number) => {
        if (originHeight.current === 0) originHeight.current = height;
    };

    const isOfficial =
        chatInfo.participants?.length > 0 &&
        (chatInfo.participants[0].is_official || chatInfo.participants[1].is_official);

    return (
        <AnimatePresence>
            {openChat && (
                <motion.div
                    variants={popupX}
                    initial="initial"
                    animate="animate"
                    className={style.chat}
                    transition={{
                        duration: 0.1
                    }}
                >
                    <ChatHeader
                        currentChat={currentChat}
                        switchChat={switchChat}
                        isCp={isCp}
                        pageMode={pageMode}
                        isOfficial={isOfficial}
                    />
                    <ChatNotice />
                    <ChatBody
                        userInfo={userInfo}
                        chatInfo={chatInfo}
                        chatMessageList={chatMessageList}
                        isEnd={isEnd}
                        handleLoadMore={handleLoadMore}
                        switchDetail={switchDetail}
                        bodyHeight={bodyHeight}
                        setOriginHeight={setOriginHeight}
                        originHeight={originHeight.current}
                        isOfficial={isOfficial}
                    />
                    {!isOfficial && (
                        <ChatFooter
                            mode={currentChat.mode || 'user'}
                            chatInfo={chatInfo}
                            cpIsClose={cpIsClose}
                            setShowMedia={setShowMedia}
                            reCalcBodyHeight={reCalcBodyHeight}
                            userInfo={userInfo}
                            chatMessageList={chatMessageList}
                        />
                    )}
                    <MediaPopup
                        mode={currentChat.mode || 'user'}
                        chatInfo={chatInfo}
                        showMedia={showMedia}
                        setShowMedia={setShowMedia}
                    />
                    {chatInfo.id && (
                        <MediaDetail
                            open={showDetail}
                            detail={detailInfo}
                            chatInfo={chatInfo}
                            close={switchDetail}
                        />
                    )}
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default Chat;
