'use client';

import { useState } from 'react';

import CpMediaInfoCard from '@/components/cp/cpMediaInfoCard/cpMediaInfoCard';
import Footer from '@/features/footer/footer';
import Header from '@/features/header/header';
import { CpPornAlbumTrailerVideo, CpPornAlbumInfo, CpInfoType } from '@/types/cpType';

function VideoInfo({
    userInfo,
    videoInfo,
    albumInfo,
    onClose
}: {
    userInfo: CpInfoType;
    videoInfo: CpPornAlbumTrailerVideo;
    albumInfo: CpPornAlbumInfo;
    onClose?: () => void;
}) {
    const [videoData, setVideoData] = useState<CpPornAlbumTrailerVideo>({ ...videoInfo, isMuted: true });

    const handleMuted = () => {
        setVideoData((preVideo) => {
            return {
                ...preVideo,
                isMuted: !preVideo.isMuted
            }
        })
    }

    return (
        <>
            <Header />
            <CpMediaInfoCard
                onClose={onClose}
                userInfo={userInfo}
                mediaData={videoData}
                isVideo
                isPorn
                isTrailer
                albumInfo={albumInfo}
                handleMuted={handleMuted}
            />
            <Footer />
        </>
    );
}

export default VideoInfo;
