'use client';
import { ReactNode } from 'react';

import { createChatStore } from '@/store/chat';
import { createCpCreateStore } from '@/store/cpCreate';
import { createCpMediaStore } from '@/store/cpMedia';
import { createDepositStore } from '@/store/deposit';
import { createNotifyStore } from '@/store/notify';
import { createResourceStore } from '@/store/resource';
import { createVideoFeedStore } from '@/store/videoFeed';

function StoreProvider({ children }: { children: ReactNode }) {
    createNotifyStore();
    createDepositStore();
    createChatStore();
    createCpCreateStore();
    createResourceStore();
    createCpMediaStore();
    createVideoFeedStore();

    return <>{children}</>;
}

export default StoreProvider;
