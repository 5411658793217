'use client';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useTranslations } from 'next-intl';
import { useState, useEffect, useRef } from 'react';

import { getCountryCodeList, sendSms, bindPhone } from '@/api/auth/auth';
import BaseButton from '@/components/baseButton/baseButton';
import BaseInput from '@/components/baseInput/baseInput';
import TurnstileWidget from '@/components/turnstileWidget/turnstileWidget';
import { useNotifyStore } from '@/store/notify';
import { useUserStore } from '@/store/user';

import BackIcon from './img/back.svg';
import CloseIcon from './img/close.svg';

import style from './validatePhone.module.scss';

function CountryCodeSelect({
    setCountryCode,
    countryCode,
    countryCodeList
}: {
    countryCode: string;
    setCountryCode: (val: string) => void;
    countryCodeList: { label: string; value: string }[];
}) {
    const handleCountryCode = (e: SelectChangeEvent) => {
        setCountryCode(e.target.value);
    };

    return (
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={countryCode}
            onChange={handleCountryCode}
            className={style.countryCodeSelect}
        >
            {countryCodeList.map(item => (
                <MenuItem value={item.value} key={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </Select>
    );
}

export function useValidatePhoneHook() {
    const setValidatePhoneModalVisible = useNotifyStore.use.setValidatePhoneModalVisible();
    const userInfo = useUserStore.use.userInfo();

    const checkValidatePhone = () => {
        if (userInfo?.is_completed && !userInfo?.is_phone_binding) {
            setValidatePhoneModalVisible(true);
            return true;
        }
        return false;
    };

    return { checkValidatePhone };
}

function ValidatePhone() {
    const t = useTranslations();
    const userInfo = useUserStore.use.userInfo();
    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const setValidatePhoneModalVisible = useNotifyStore.use.setValidatePhoneModalVisible();
    const validatePhoneModalVisible = useNotifyStore.use.validatePhoneModalVisible();
    const updateUserInfoStore = useUserStore.use.updateUserInfo();

    const openPopup = () => {
        setValidatePhoneModalVisible(true);
    };
    const closePopup = () => {
        setValidatePhoneModalVisible(false);
    };

    const [step, setStep] = useState(1);

    const [countryCodeList, setCountryCodeList] = useState<{ label: string; value: string }[]>([]);
    const [countryCode, setCountryCode] = useState('');

    const fetchGetCountryList = async () => {
        const res = await getCountryCodeList();
        if (res.status !== 200) return {}; // TODO: ERROR HANDLE

        const list = Object.values(res.data).map(item => {
            return { label: `+${item}`, value: item };
        });
        setCountryCodeList(list);
        setCountryCode('886');
    };

    useEffect(() => {
        if (validatePhoneModalVisible) fetchGetCountryList();
    }, [validatePhoneModalVisible]);

    const [phone, setPhone] = useState('');

    const [verifyToken, setVerifyToken] = useState('');
    const handleTurnstileVerify = (token: string) => {
        setVerifyToken(token);
    };

    const [isLoadingSend, setIsLoadingSend] = useState(false);

    const next = async () => {
        let message = '';
        if (!countryCode) message = t('phoneVerify_countryCode_tip');
        else if (!phone) message = t('phoneVerify_phone_tip');
        else if (!/^\d+$/.test(phone)) message = t('phoneVerify_phone_error');

        if (message) {
            setSnackMessage({ message });
            return;
        }

        if (verifyToken.length === 0) {
            setSnackMessage({ message: t('phoneVerify_verifyRealPerson_tip') });
            return;
        }

        setIsLoadingSend(true);
        const res = await sendSms({
            country: countryCode,
            number: phone,
            cfTurnstileResponse: verifyToken
        });
        if (res.status !== 200) {
            setSnackMessage({ message: res?.errorMsg });
            setIsLoadingSend(false);
            return;
        }

        setResendCount(60);
        setStep(2);
        setIsLoadingSend(false);
    };

    const [smsCode, setSmsCode] = useState('');
    const [isLoadingResend, setIsLoadingResend] = useState(false);
    const [resendCount, setResendCount] = useState(0);
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
    useEffect(() => {
        if (resendCount > 0) {
            timer.current = setTimeout(() => {
                setResendCount(resendCount - 1);
            }, 1000);
        }
        return () => {
            if (timer.current) clearInterval(timer.current);
        };
    }, [resendCount]);
    const resend = async () => {
        setIsLoadingResend(true);
        const res = await sendSms({
            country: countryCode,
            number: phone,
            cfTurnstileResponse: verifyToken
        });
        if (res.status !== 200) {
            setSnackMessage({ message: res?.errorMsg });
            setIsLoadingSend(false);
            return;
        }
        setIsLoadingResend(false);
        setResendCount(60);
    };

    const [isLoadingComplete, setIsLoadingComplete] = useState(false);
    const complete = async () => {
        setIsLoadingComplete(true);
        const res = await bindPhone({
            country: countryCode,
            number: phone,
            code: smsCode
        });

        if (res?.status !== 200) {
            setSnackMessage({ message: res?.errorMsg });
            setIsLoadingComplete(false);
            return;
        }
        setSnackMessage({ message: t('phoneVerify_binding_success_tip') });
        window.gtag('event', 'verified_reg', { userId: userInfo.user_id });

        updateUserInfoStore({
            is_phone_binding: true
        });
        setIsLoadingComplete(false);
        setValidatePhoneModalVisible(false);
    };

    return (
        <>
            {validatePhoneModalVisible && (
                <SwipeableDrawer
                    PaperProps={{
                        style: {
                            borderTopLeftRadius: '16px',
                            borderTopRightRadius: '16px'
                        }
                    }}
                    disableSwipeToOpen
                    anchor="bottom"
                    onClose={closePopup}
                    onOpen={openPopup}
                    open={validatePhoneModalVisible}
                    swipeAreaWidth={20}
                    transitionDuration={{ enter: 300, exit: 200 }}
                >
                    <div className={style.validatePhone}>
                        <div className={style.topIcon}>
                            {step === 2 && <BackIcon onClick={() => setStep(1)} />}
                            <CloseIcon className={style.closeIcon} onClick={closePopup} />
                        </div>

                        {step === 1 && (
                            <>
                                <div className={style.title}>
                                    {t('phoneVerify_phoneTitle_text')}
                                </div>
                                <BaseInput
                                    label={`${t('phoneVerify_phoneInput_label')}*`}
                                    value={phone}
                                    onChange={value => setPhone(value)}
                                    frontEl={
                                        <CountryCodeSelect
                                            countryCodeList={countryCodeList}
                                            setCountryCode={setCountryCode}
                                            countryCode={countryCode}
                                        />
                                    }
                                    inputmode="numeric"
                                    placeholder={t('phoneVerify_phoneInput_placeholder')}
                                />

                                <div className={style.turnstile}>
                                    <label className={style.label}>
                                        {t('phoneVerify_verifyRealPerson_label')}
                                    </label>
                                    <TurnstileWidget onVerify={handleTurnstileVerify} />
                                </div>

                                <BaseButton
                                    type={'normal'}
                                    className={style.btn}
                                    loading={isLoadingSend}
                                    onClick={next}
                                    disabled={verifyToken.length === 0}
                                    allowDisabledOnClick
                                >
                                    {t('phoneVerify_continue_button')}
                                </BaseButton>
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <div className={style.title}>
                                    {t('phoneVerify_codeTitle_text')}
                                    <br />
                                    {`+${countryCode} ${phone}`}
                                </div>
                                <BaseInput
                                    label={t('phoneVerify_codeInput_label')}
                                    value={smsCode}
                                    onChange={value => setSmsCode(value)}
                                    placeholder={t('phoneVerify_codeInput_placeholder')}
                                    backEl={
                                        <BaseButton
                                            type={'normal'}
                                            className={`${style.resend} ${resendCount > 0 ? style.disabled : ''
                                                }`}
                                            loading={isLoadingResend}
                                            disabled={resendCount > 0}
                                            onClick={resend}
                                        >
                                            {resendCount > 0
                                                ? `${resendCount}s`
                                                : t('phoneVerify_sendCode_button')}
                                        </BaseButton>
                                    }
                                />
                                <BaseButton
                                    className={style.btn}
                                    type={'normal'}
                                    loading={isLoadingComplete}
                                    onClick={complete}
                                >
                                    {t('phoneVerify_finish_button')}
                                </BaseButton>
                            </>
                        )}
                    </div>
                </SwipeableDrawer>
            )}
        </>
    );
}

export default ValidatePhone;
